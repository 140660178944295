.loadingScreen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: var(--white);
    opacity: 1;
    pointer-events: all;
    user-select: none;
    transition: opacity 0s linear;
    z-index: 100;
}

.loaded {
    opacity: 0;
    pointer-events: none;
    // transition: opacity 0.125s ease-out;
}

// .unloaded {
// }
