@import "../global/mixins";

.container {
    // position: relative;
}

.hidden {
    opacity: 0 !important;
}

button.desktopTopicDropdown {
    min-height: var(--nav-height);
}

.closeBtn {
    svg {
        transform: rotate(45deg);
    }
}

.toggleContainer {
    position: relative;
    z-index: 11;

    button {
        &:hover + .menuTooltip {
            display: block;
        }
    }

    .menuTooltip {
        width: getRem(288);
        display: none;
        position: absolute;
        top: calc(100% + 6px);
        left: 0;

        .tooltipInner {
            background: var(--yellow);
            border-radius: getRem(4);
            box-shadow: 0px 9px 13px 0px rgba(0, 0, 0, 0.24);
            padding: getRem(16);
            position: relative;

            &:before {
                content: "";
                width: 0;
                height: 0;
                border-bottom: getRem(6) solid var(--yellow);
                border-left: getRem(6) solid transparent;
                border-right: getRem(6) solid transparent;
                display: inline-block;
                position: absolute;
                top: getRem(-6);
                left: getRem(16);
            }

            p {
                @include font(16, 26);
                margin: 0;
            }
        }
    }
}

.dropdown {
    --border-color: rgba(45, 45, 45, 0.2);
    --dropdown-width: 100vw;
    width: 100vw;
    height: calc(100vh - var(--nav-height));
    //height: 100vh;
    position: absolute;
    // top: calc(100% + ((var(--nav-height) - 40px) / 2));
    top: 100%;
    left: 0;
    z-index: 1;
    color: var(--black);
    border-radius: getRem(6);

    a {
        color: var(--black);
    }

    .dropdownInner {
        width: 100%;
        height: 100%;
        display: flex;
        position: relative;

        &:before {
            content: "";
            width: var(--padding);
            height: 100%;
            background: var(--white);
            display: inline-block;
            position: absolute;
            top: 0;
            left: calc(var(--padding) * -1);
            z-index: 1;

            @media (min-width: 1441px) {
                width: calc((100vw - var(--max-width)) / 2 + 32px);
                left: calc(((100vw - var(--max-width)) / 2 + 32px) * -1);
            }
        }

        .dropdownBackground {
            width: 100vw;
            // height: calc(100vh - var(--nav-height));
            height: 100vh;
            background-color: rgba(0, 0, 0, 0.8);
            position: fixed;
            top: var(--nav-height);
            left: 0;
            z-index: 0;
        }

        .panel {
            width: auto;
            max-width: 33.33%;
            height: 100%;
            background: var(--white);
            display: none;
            position: relative;
            z-index: 1;

            &.show {
                display: block;
            }

            .panelInner {
                height: 100%;
                background: var(--white);
                padding: getRem(48) getRem(24);
                position: relative;

                @media (max-width: 1380px) {
                    padding: getRem(48) getRem(16);
                }
            }

			:global {
				.width-2 {
					@media (max-height: 900px) {
						min-width: 28vw;
					}

					@media (max-height: 450px) {
						min-width: 42vw;
					}
				}

				.width-3 {
					min-width: 28vw;
					
					@media (max-height: 900px) {
						min-width: 42vw;
					}
				}
			}

            .panelMain {
                height: 100%;
                overflow: auto;
            }

			.panelColumnContainer {
				height: 100%;

				ul {
					padding-bottom: 6rem;
				}
			}

			:global {
				.columns-2 {
					@media (max-height: 900px) {
						display: grid;
						grid-template-columns: repeat(2, 1fr);
						align-items: center;
						gap: getRem(16);
						width: 100%;

						li:nth-child(n) {
							margin-top: 0;
						}
					}

					@media (max-height: 450px) {
						display: grid;
						grid-template-columns: repeat(3, 1fr);
						align-items: center;
						gap: getRem(16);
						width: 100%;

						li:nth-child(n) {
							margin-top: 0;
						}
					}
				}

				.columns-3 {
					display: grid;
					grid-template-columns: repeat(2, 1fr);
					align-items: center;
					gap: getRem(16);
					width: 100%;

					li:nth-child(n) {
						margin-top: 0;
					}

					@media (max-height: 900px) {
						display: grid;
						grid-template-columns: repeat(3, 1fr);
						align-items: center;
						gap: getRem(16);
						width: 100%;

						li:nth-child(n) {
							margin-top: 0;
						}
					}
				}
			}

            .closeBtn {
                width: getRem(22);
                height: getRem(22);
                position: absolute;
                top: getRem(16);
                right: getRem(24);

                span {
                    width: getRem(22);
                    height: getRem(22);
                    font-size: getRem(2);
                    opacity: 0;
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: -1;
                }

                svg {
                    width: 100%;
                    height: 100%;
                    margin-right: 0;
                    transform: rotate(45deg);

                    path {
                        fill: #000000;
                    }
                }
            }
            .menuParentItem {
                margin-top: getRem(36);
				padding-right: getRem(16);

				&:first-of-type {
					margin-top: 0;
				}
            }

            h2,
            h3 {
                color: var(--red);
                font-size: getRem(22);
                font-weight: 700;
                line-height: getRem(30);
                margin: 0;
            }

            ul {
                list-style: none;
                margin-top: getRem(20);
                padding: 0;

                & + h2 {
                    margin-top: getRem(36);
                }
            }

            li {
                display: flex;
                align-items: center;
                margin-right: 0;
                position: static;

                &:not(:first-child) {
                    margin-top: getRem(16);
                }
            }

            .parentItem {
                &:after {
                    content: "";
                    width: getRem(12);
                    height: getRem(12);
                    background: url("/../../images/ui-menu-arrow.svg") 50%
                        no-repeat;
                    background-size: contain;
                    display: inline-block;
                    vertical-align: middle;
                    margin-left: auto;
                }
            }

            a,
            button,
            span {
                width: 100%;
                color: var(--text-color);
                font-size: getRem(16);
                font-weight: 700;
                line-height: getRem(20);
                display: inline-flex;
                align-items: center;
                position: relative;
                white-space: normal;
                text-align: left;
            }

            a,
            button {
                &:hover,
                &:focus {
                    text-decoration: underline;
                }
            }

            .newTag {
                font-size: getRem(16);
                font-weight: 700;
                line-height: getRem(20);
                position: relative;
                margin-left: getRem(12);

                &:after {
                    content: "";
                    width: calc(100% + 8px);
                    height: calc(100% + 8px);
                    background: var(--yellow);
                    border-radius: 3px;
                    position: absolute;
                    top: -4px;
                    left: -4px;
                    z-index: -1;
                }
            }
        }

        .subPanel {
            height: 100%;
            border-left: 1px solid #d6d6d6;
            display: block;
            position: absolute;
            top: 0;
            left: 100%;
            // transition: all 2s ease-in-out;
            visibility: hidden;
            z-index: -1;

            @media (min-width: 1441px) {
                min-width: getRem(215);
            }

            @media (max-width: 1440px) {
                min-width: 14.4vw;
            }

            @media (max-width: 1380px) {
                min-width: 13.6vw;
            }

            &.show {
                left: 100%;
                visibility: visible;
            }

            .panelInner {
                padding: getRem(48) 0;
            }

            h3 {
                position: relative;

                // @media (min-width: 1381px) {
                //   white-space: nowrap;
                // }
            }

            a,
            button {
                @media (max-width: 1380px) {
                    white-space: normal;
                    // word-break: break-all;
                    text-align: left;
                }
            }

            .headerRow {
                display: flex;
                position: relative;
                padding: 0 getRem(24);

                .arrowBtn {
                    width: getRem(14);
                    height: getRem(14);
                    position: absolute;
                    top: 50%;
                    left: getRem(-24);
                    transform: translateY(-50%);

                    span {
                        width: getRem(12);
                        height: getRem(12);
                        font-size: getRem(2);
                        opacity: 0;
                        position: absolute;
                        top: 0;
                        left: 0;
                        z-index: -1;
                    }

                    svg {
                        width: 100%;
                        height: 100%;
                        margin-right: 0;
                        transform: rotate(180deg);
                    }
                }
            }

            .closeBtn {
                width: getRem(22);
                height: getRem(22);
                position: absolute;
                top: getRem(16);
                right: getRem(24);

                span {
                    width: getRem(22);
                    height: getRem(22);
                    font-size: getRem(2);
                    opacity: 0;
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: -1;
                }

                svg {
                    width: 100%;
                    height: 100%;
                    margin-right: 0;
                    transform: rotate(45deg);

                    path {
                        fill: #000000;
                    }
                }
            }

            ul {
                max-height: calc(100vh - 118px);
                overflow: auto;
                padding: 0 getRem(24) getRem(24) getRem(24);
            }

            li {
                &:not(:first-child) {
                    margin-top: getRem(24);
                }
            }

            .subPanel {
                a,
                button,
                span {
                    font-weight: 400;
                }
            }
        }

        .topicsPanel {
            a,
            button,
            span {
                white-space: nowrap;
            }
        }
    }
}

.buttonContainer {
    position: relative;
    margin-top: getRem(30);
    text-align: center;
    margin-bottom: getRem(0);
    background: #ffffff;
    z-index: 1;

    a {
        width: calc(100% - 2rem);
        margin: 0;

        &:hover {
            text-decoration: none;
        }
    }
}
