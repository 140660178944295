@import "../../global/mixins";

.searchOverlay {
	position: fixed;
	top: getRem(115);
	left: 0;
	width: 100%;
	height: calc(100vh - getRem(115));
	background-color: rgba(#000, .5);
	z-index: -1;

	@media (max-width: 1224px) {
        display: none;
    }
}

.searchBox {
    position: absolute;
    top: getRem(112);
    left: 0;
    width: 100%;
    padding-block: getRem(60) getRem(20);
    background-color: #F9F9F9;

	@media (max-width: 1224px) {
        position: relative;
		top: unset;
		left: unset;
		margin-top: getRem(20);
		padding: 0;
		outline: 0;
		background-color: #fff;
    }

	&::before {
		position: absolute;
		top: 0;
		left: -50%;
		content: '';
		display: block;
		width: 200%;
		height: 100%;
		background-color: #F9F9F9;
		z-index: -1;

		@media (max-width: 1224px) {
			display: none;
		}
	}
}

.searchForm {
	position: relative;
	margin-bottom: getRem(20);
	//padding-left: getRem(4);
}

.searchInput:nth-child(n):nth-child(n) {
	width: 100%;
    height: getRem(80);
    font-size: getRem(50);
	line-height: getRem(80);
    color: #292929;
    margin: 0;
    padding: 0 getRem(80) 0 getRem(16);
	background-color: #FFF;
    border: 0;
	border-bottom: 2px solid #000;
	border-radius: 0;

	@media (max-width: 1224px) {
		height: getRem(50);
		font-size: getRem(30);
		line-height: getRem(50);
		padding: 0 getRem(50) 0 getRem(8);
    }

	&:focus {
		border-color: transparent !important;
    	box-shadow: none;
	}

	&:focus-visible {
		border-bottom: 0 !important;
		outline: 1px solid #ffbc0d;
		outline-offset: 4px;
	}

    &::placeholder {
        font-size: getRem(50);

		@media (max-width: 1224px) {
			font-size: getRem(30);
		}
    }
}

.searchSubmit {
	position: absolute;
	top: 50%;
	right: getRem(16);
    display: flex;
    justify-content: center;
    align-items: center;
    width: getRem(60);
    height: getRem(48);
    background-color: #ffbc0d;
    border: 0;
    border-radius: getRem(3);
    appearance: none;
	transform: translateY(-50%);
	z-index: 1;

	@media (max-width: 1224px) {
		right: getRem(8);
    }

    svg {
        width: getRem(16);
        height: getRem(16);
        pointer-events: none;
        transform-origin: center;
        transition: transform 0.3s ease;
    }
}

.searchResults {
	max-height: 33vh;
	padding-bottom: getRem(40);
	overflow: auto;

	@media (max-width: 1224px) {
		max-height: unset;
    }
}

.searchResultsHead {
    font-size: getRem(14);
    color: #777676;
    margin-block: getRem(40) getRem(8);

	@media (max-width: 1224px) {
		margin-top: getRem(20);
    }
}

.searchResultsList {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: getRem(16);
	padding-bottom: getRem(40);
	border-bottom: 1px solid rgba(#2D2D2D, .2);

	@media (max-width: 1224px) {
		padding-bottom: getRem(20);
    }

	&:last-of-type {
		padding-bottom: 0;
		border-bottom: 0;
	}

	@include breakpoint(tablet) {
		grid-template-columns: repeat(2, 1fr);
    }

	@include breakpoint(mobile) {
		grid-template-columns: 1fr;
	}
}

.searchResultsItem {
    display: flex;
    align-items: center;
    gap: getRem(16);

	&:hover {
		p {
			text-decoration-color: inherit;
		}
	}
}

.searchResultsImage {
	flex: 0 0 getRem(40);
    width: getRem(40);
    height: getRem(40);

    img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.searchResultsIcon {
	flex: 0 0 getRem(40);
	display: flex;
	justify-content: center;
	align-items: center;
    width: getRem(40);
    height: getRem(40);
	padding: getRem(5);
	background-color: #ffbc0d;

    svg {
        display: block;
        width: 100%;
    }
}

.searchResultsTitle {
    font-size: getRem(20);
	text-decoration: underline;
	text-decoration-color: transparent;
    margin: 0;
	transition: text-decoration-color .3s ease;

	@media (max-width: 1224px) {
		font-size: getRem(18);
    }
}
