@import "../global/mixins";

.searchbar {
  --search-image: url("/images/search.svg");
  // background-color: purple;
  // width: 50vw;
  width: 100%;
  // max-width: 541px;
  max-width: 100%;
  padding: 5px;
  --element-color: var(--black);
  margin-left: auto;
  margin-right: auto;

  // TODO: Temp for tablet and mobile
  @include breakpoint(mobile-tablet) {
    width: 100%;
  }

  form {
    display: block;
    // background-color: blue;
    border-bottom: 1px solid var(--element-color);

    &:focus-within {
      background-color: rgba(#ffbc0d, 0.125);
      outline: none;
    }

    input {
      width: calc(100% - 50px);
      appearance: none;
      background-color: Transparent;
      height: 50px;
      border: none !important;
      padding: 0 2em;
      color: var(--element-color);
      font-size: 1rem;
      font-weight: normal;
      margin-bottom: 0 !important;

      &:focus {
        outline: none;
      }
    }

    button[type="submit"] {
      width: 50px;
      height: 50px;
      background-color: transparent;
      appearance: none;
      border: 1px solid transparent;
      color: var(--element-color);
      cursor: pointer;
    }
  }

  :global .ais-Hits {
    ul.ais-Hits-list {
      width: 100%;
      list-style: none;
      display: grid;
      grid-template-columns: 1fr;
      gap: 1rem;
      margin: 0;
      padding: 1em 0;

      @include breakpoint(mobile) {
        display: flex;
        flex-direction: column;
        padding: 1em;
      }
    }
  }

  input[type="search"]::-webkit-search-decoration,
  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-results-button,
  input[type="search"]::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }
}

.searchResults {
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  font-size: 0.5em;
  max-height: calc(50vh - 67px);

  @include breakpoint(mobile) {
    max-height: calc(50vh - 80px);
  }
}

.searchResultsItem {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: .5rem 1rem;
	transition: background-color .3s ease;

	&:hover {
		background-color: #FFBC0D;
	}

	&:first-of-type {
		margin-top: .5rem;
	}

	button:nth-child(n):nth-child(n) {
		position: relative;
		top: unset;
		right: unset;
		width: auto;
		height: auto;
		background-color: transparent;
		border: 0;
		appearance: none;
	}
}

.searchResultsLink {
	display: flex;
	align-items: center;
	gap: .5rem;
	font-size: 1.125rem;
	color: #000;
	margin: 0;

	svg {
		display: inline-block;
	}
}

.searchResultsRemove {
	span {
		display: block;
		font-size: 2rem;
		color: #292929;
		transform: rotate(45deg);
	}
}

.results {
  color: var(--text-color);
  background-color: var(--light-grey);
  position: absolute;
  max-height: 50vh;
  overflow: auto;
  width: 100%;
  font-size: 0.5em;
  transform-origin: top center;
  transform: scaleY(0);
  transition: transform 0.33s md-ease(ease-in-out) 0.125s;
  box-shadow: var(--shadow);
}

.searchContainer {
  position: relative;
  max-width: 100%;

  // background-color: purple;
  :global {
    button {
      $button-size: getRem(30);
      position: absolute;
      top: 0;
      right: 0;
      width: $button-size;
      height: getRem(50);
      appearance: none;
      border: none;

      // background-color: green;
      &[type="reset"] {
        right: -$button-size;
      }
    }
  }

  :global .ais-Hits-item {
    .hit-description {
      display: none;
    }

    .hit-price {
      display: none;
    }

    img {
      display: none;
    }
  }

  :global .recentSearches {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: auto;
    padding: 0.25em 1em;
    background-color: white;
  }

  &:focus-within {

    // :global .ais-Hits {
    //     transform: scale(1);
    // }
    :global .recentSearches {
      display: block;
    }

    .results {
      transition: transform 0.33s md-ease(ease-in-out);
      transform: scale(1);
    }
  }

  :global .ais-SearchBox-form {
    position: relative;

    button[type="reset"] {
      display: none;
    }

    button[type="submit"] {

      // position: relative;
      &::after {
        content: var(--search-image);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        filter: contrast(200);
        margin-top: getRem(4);
      }

      svg {
        display: none;
      }
    }
  }
}

.searchBarContainer {
  background: #F7F7F7;
  border-radius: 6px 6px 0px 0px;
  display: flex;
  // overflow: hidden;
  position: relative;
  z-index: 10;

  &>div:first-child {
    width: 100%;
    
    input[type="search"] {
      color: var(--black);
      padding-left: getRem(48);
      padding-right: getRem(180);

      @include breakpoint(mobile) {
        // padding-left: getRem(16);
        padding-right: getRem(16);
      }
    }

    button[type="submit"] {
      left: 0;
      right: auto;

      &:after {

      }
    }
  }

  .selectType {
    border-left: 1px solid var(--black);
    padding-left: getRem(12);
    flex-shrink: 0;
    position: absolute;
    top: 50%;
    right: getRem(16);
    transform: translateY(-50%);

    @include breakpoint(mobile) {
      display: none;
    }

    .selectTypeInner {
      position: relative;
    }

    button {
      width: getRem(180);
      height: auto;
      background: none;
      border-radius: getRem(4);
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include font(14, 26);
      padding: getRem(4) getRem(8);
      position: static;
      transition: all .2s ease-in-out;

      &:hover {
        background: #E3E3E3;
      }

      svg {
        margin-top: getRem(-6);
        margin-left: getRem(8);

        path {
          fill: var(--black);
        }
      }
    }

    ul {
      min-width: getRem(204);
      background: var(--white);
      box-shadow: 0px 2px 13px rgba(0, 0, 0, 0.202387);
      border-radius: 0px 0px 3px 3px;
      list-style: none;
      padding: getRem(8);
      position: absolute;
      top: 100%;
      left: 0;
      z-index: 1;

      li {
        &:not(:first-child) {
          margin-top: getRem(4);
        }

        &.activeType {
          opacity: 0;
          position: absolute;
          top: 0;
          left: 0;
          z-index: -1;
        }

        label {
          color: var(--black);
          cursor: pointer;
          @include font(16, 32);
          position: relative;

          &:hover {
            text-decoration: underline;
          }

          input {
            opacity: 0;
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
          }
        }
      }
    }
  }
}

.peopleSearchbox,
.resultSearchbox {
  display: none;
}

.dark {
  --element-color: var(--white);
  // --search-image: url("/images/search-white.svg");

  form:focus-within {
    background-color: rgba(white, 0.125);
  }
}

.hitTopic {
  font-size: getRem(14);
  color: var(--text-light2);
  letter-spacing: getRem(1.75);
  line-height: getRem(18);
  text-transform: uppercase;
  font-weight: normal;
  padding-left: 1rem;
}

.hitTitle {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  // @include breakpoint(mobile) {
  //   flex-direction: column;
  //   align-items: flex-start;
  // }

  // padding-right: 1rem;
  svg {
    margin-right: 1rem;
  }

  color: var(--text-color);

  // width: 100%;
  :global .ais-Highlight {
    // max-width: calc(100% - 3rem);
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.hit {
  // display: grid;
  width: 100%;
  // grid-template-columns: 2fr auto;
  padding: 0.25em 0.5em;
  gap: -0.25px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 100%;

  &>span {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    align-items: center;
    // line-height: 1em;
    font-weight: normal;
  }

  .hitTitle {
    width: auto;
    max-width: getRem(350);
    flex-grow: 0;
    flex-shrink: 1;

    em {
      font-style: normal;
      font-weight: bold;
    }
  }

  .hitTitleStravito {
    max-width: calc(100% - 50px);
    text-overflow: ellipsis;
    overflow: hidden;

    :global .title {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-bottom: 2px;
    }
  }

  .hitTopic {
    width: auto;
    flex-shrink: 0;
    flex-grow: 1;

    @include breakpoint(mobile) {
      display: none;
    }
  }

  :global .ais-Highlight-highlighted {
    font-weight: bold;
    font-style: normal;
  }

  &::after {
    position: absolute;
    content: "";
    width: 100%;
    height: calc(100% + 1.5rem);
    top: 0;
    left: 0;
    background-color: transparent;
    z-index: -1;
    transform: translateY(-0.75rem);
  }

  &:focus,
  &:hover {
    text-decoration: none;

    &::after {
      background-color: var(--yellow);
    }
  }
}

.seeAll {
  height: 67px;
  // padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-top: 1px solid rgba(#292929, 0.2);

  @include breakpoint(mobile) {
    border-top: none;
    margin-bottom: 12px;
  }

  a {
    width: calc(100% - 2rem);
    margin: 0 !important;
    text-align: center;
  }
}

.hitList {
  width: 100%;
  list-style: none;
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  margin: 0;
  padding: 1em 0;

  a {
    font-weight: normal;

    &:hover {
      color: var(--black);
    }
  }
}

.caption {
  padding: 0.5rem 1rem 0;
}

.iconContainer {
  // width: getRem(30);
  display: block;
  flex-shrink: 0;

  @include breakpoint(mobile) {
    display: flex;
    align-items: center;
  }

  svg {
    display: block;
    width: getRem(20);
    height: auto;
  }
}

.stravitoBlock {
  border-top: 1px solid rgba(#292929, 0.2);
  width: 100%;
  display: block;
}

.iconContainer {
  svg path {
    fill: #777676 !important;
  }
}