@import "../global/mixins";

.container {
  // position: relative;
  display: flex;
  align-items: center;
}

.desktopNotifsDropdown {
  width: getRem(26);
  height: getRem(26);
  position: relative;

  span {
    width: 100%;
    height: 100%;
    font-size: 2px;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
}

.dropdown {
  width: getRem(400);
  position: absolute;
  top: calc(100% + 4px);
  // top: getRem(64);
  left: 50%;
  background: #F7F7F7;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  transform: translateX(-50%);

  .top {
    width: 100%;
    background: var(--yellow);
    border-radius: 4px 4px 0 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: getRem(8) getRem(16);

    h2 {
      font-weight: 700;
      font-size: getRem(12);
      line-height: getRem(15);
      margin: 0;
      color: #000000;
    }

    button {
      background: none;
      border: none;
      padding: 0;
      font-weight: 400;
      font-size: getRem(12);
      line-height: getRem(15);
      color: #000000;

      &:focus,
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .notifcationsContainer {
    max-height: getRem(440);
    overflow: auto;
    padding: 0 getRem(16);

    li {
      margin: 0;
      padding: getRem(16) 0;

      &:not(:last-child) {
        border-bottom: 1px solid #ADADAD;
      }
    }

    h3,
    p {
      color: #000000;
      font-size: getRem(14);
      line-height: getRem(17);
      margin: 0;
    }

    p {
      margin: getRem(8) 0 0;
    }

    p.date {
      font-size: getRem(12);
      line-height: getRem(15);
      color: #000000;
      opacity: 0.6;
    }
  }

  .bottom {
    background: var(--yellow);
    border-radius: 0 0 4px 4px;
    display: flex;
    padding: getRem(8) getRem(16);

    button {
      width: getRem(20);
      height: getRem(20);
      background: none;
      border: none;
      margin-left: auto;
      padding: 0;
      position: relative;

      &:focus {
        outline: 1px solid black;
      }

      span {
        position: absolute;
        top: 0;
        left: 0;
        text-indent: -9000px;
      }

      svg {
        path {
          fill: transparent;
        }
      }
    }
  }
}

.notifItem {
  display: flex;
  margin: 0 !important;
  padding: getRem(16) !important;

  &:not(:last-child) {
    border-bottom: 1px solid #ADADAD !important;
  }

  .textContainer {
    width: 100%;
    position: relative;

    a {
      width: 100%;
      height: 100%;
      display: inline-block;
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;

      &:hover,
      &focus {
        text-decoration: none;
      }
    }

    div,
    h3,
    p {
      color: #000000;
      font-size: getRem(14) !important;
      line-height: getRem(17) !important;
      margin: 0 !important;
    }

    .content {
      margin: getRem(8) 0 0 !important;
    }

    p.date {
      font-size: getRem(12);
      line-height: getRem(15);
      color: #000000;
      margin: getRem(8) 0 0 !important;
      opacity: 0.6;
    }
  }

  .statusContainer {
    width: getRem(25);
    height: 100%;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    margin: auto 0;
    position: relative;

    .optionsToggle {
      width: getRem(25);
      height: getRem(25);
      border: none;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      position: relative;

      .unreadDot {
        width: getRem(8);
        height: getRem(8);
        background: var(--red);
        border-radius: 50%;
        margin: auto;
      }

      .text {
        width: 100%;
        height: 100%;
        font-size: 2px;
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        // text-indent: 9000px;
      }

      svg {
        width: auto;
        height: 100%;
      }

      &.open,
      &:focus,
      &:hover {
        svg circle {
          fill: #000000;
        }
      }

      &.unread {
        svg circle {
          &:first-child,
          &:last-child {
            fill: transparent;
          }
          &:nth-child(2) {
            fill: var(--red);
          }
        }

        &.open,
        &:focus,
        &:hover {
          svg circle {
            fill: #000000;
          }
        }
      }
    }

    .notifOptions {
      background: white;
      box-shadow: 0 0 4px rgba(0,0,0,.2);
      list-style: none;
      margin: 0;
      padding: 0 getRem(8);
      position: absolute;
      top: 100%;
      right: 0;
      z-index: 100;

      li {
        &:not(:lsat-child) {
          border-bottom: 1px solid #ADADAD !important;
        }
      }

      button {
        background: none;
        border: none;
        color: #000000;
        font-weight: 400;
        font-size: getRem(14);
        line-height: getRem(17);
        padding: getRem(8) 0;
        white-space: nowrap;
      }
    }
  }
}
