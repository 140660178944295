@import "../global/mixins";

.container {
    position: relative;

    button {
        svg {
            width: getRem(19);
            height: getRem(19);
        }
    }
}

.dropdown {
    --border-color: rgba(45, 45, 45, 0.2);
    --dropdown-width: #{getRem(173)};
    width: var(--dropdown-width);
    height: auto;
    position: absolute;
    z-index: 1;
    background-color: transparent;
    color: var(--black);
    right: -1rem;
    &.extraWide {
        --dropdown-width: #{getRem(500)};
        right: -100%;
    }
    a {
        color: var(--black);
    }
    &::before {
        content: var(--dropdown-indicator);
        position: absolute;
        top: 0;
        // left: calc(50% - #{getRem(20)});
        right: getRem(3);
    }
    &::after {
        content: "";
        z-index: -1;
        width: 100%;
        height: calc(100% - #{getRem(24)});
        position: absolute;
        bottom: 0;
        left: 0;
        border-radius: getRem(6);
        background-color: var(--white);
        box-shadow: var(--shadow);
    }
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        position: relative;
        background-color: var(--border-color);
        display: grid;
        gap: 1px 0px;
        grid-template-columns: 1fr;
        border-top: 1px solid transparent;
        top: getRem(30);
        border: none;
        li {
            background-color: var(--white);
            margin: 0 !important;
            // padding: 0;
            padding: getRem(19) getRem(21);
            width: 100%;
            // border-top: 1px solid transparent;
            // border-bottom: 1px solid transparent;
            text-align: center;
            a {
                font-size: 4em;
                font-size: getRem(18);
                font-weight: bold;
                color: var(--black);
                position: relative;
                display: block;
                // width: calc(100% - #{getRem(40)});
                text-align: center;
                // margin-bottom: -.25rem;
                margin: getRem(10) auto;
                width: auto;
                // color: var(--red);
                line-height: 1em;
                // font-size: getRem(14);
            }
            &.functionAdmin a {
                color: var(--red);
                font-size: getRem(14);
            }
        }
        li a {
            display: inline-block;
            border-top: 2px solid transparent;
            border-bottom: 2px solid transparent;
            text-align: center;
            &:hover {
                // background-color: var(--yellow);
                color: var(--black);
                text-decoration: none;
                border-bottom-color: var(--yellow);
                // border-top-color: var(--yellow);
            }
        }
        // li:first-child a {
        //     border-top-color: var(--border-color);
        // }
    }
}

.twoColumns {
    display: grid;
    grid-template-columns: 1fr 1fr !important;
}

.buttonContainer {
    position: relative;
    margin-top: getRem(30);
    text-align: center;
    margin-bottom: getRem(0);
    a {
        width: calc(100% - 2rem);
        margin: 0;
        &:hover {
            text-decoration: none;
        }
    }
}
