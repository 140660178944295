@import "../global/mixins";

.topnav {
  // --color: var(--black);
  --background: white;
  --color: var(--text-color);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  // height: 100%;
  background-color: var(--background);
  color: var(--color);
  display: flex;
  box-shadow: var(--shadow-nav);
  // min-height: var(--nav-height);
  align-items: center;
  justify-content: flex-start;
  margin-left: auto;
  margin-right: auto;
  z-index: 1002;
  transition: color 0.25s linear, background-color 0.25s linear, transform 0.25s md-ease(ease-in-out);
  transform: translate3d(0px, -100%, 0px);

  svg path:first-child {
    fill: var(--color);
  }

  .notficationsLink {
    .css-ujd0de svg path {
      fill: var(--color);
    }
  }

  .wrapper {
    position: relative;
  }
}

.dark {
  --color: white;
  --background: rgba(41, 41, 41, 0.54);

  // TODO: Run past Emma (No!)
  // backdrop-filter: saturate(180%) blur(20px);
  .navlinks .notficationsLink a.css-ujd0de svg path {
    fill: white;
  }

  .navlinks .notficationsLink svg path {
    fill: transparent;
  }

  .navlinks .notficationsLink .defaultNotifButton {
    svg path {
      fill: white;
    }
  }
}

.sticky,
.topnav.sticky {
  transform: translate3d(0px, 0px, 0px);
}

.isSolid {
  --color: var(--text-color);
  --background: white;
  position: fixed;
}

.grid {
  min-height: var(--nav-height);
  // display: grid;
  align-items: center;
  // grid-template-columns: 1fr 48%auto;
  display: flex;
  justify-content: space-between;
  gap: 60px;
  width: 100%;
  position: relative;

  @include breakpoint(mobile-tablet) {
    grid-template-columns: 2fr 1fr 2fr;
  }

  svg {
    @include breakpoint(mobile-tablet) {
      // max-width: 100%;
      height: calc(var(--nav-height) - 1.5rem);
      // margin: 0 auto 0 0;
      // padding: 0;
      // width: auto;
    }

    @include breakpoint(desktop) {
      max-width: 200px;
    }
  }

  .left {
    display: flex;
    align-items: center;
    grid-column-gap: getRem(16);

    button {
      display: flex;
      align-items: center;
      white-space: nowrap;

      svg {
        margin-right: 4px;
      }
    }
  }

  :global {
    .search {
      width: 100%;

      @include breakpoint(mobile) {
        display: none;
      }
    }
  }

  // @include breakpoint(mobile) {
  //   display: flex;
  //   justify-content: space-between;
  // }
}

.logoContainer {
  a {
    display: inline-block;
  }

  img,
  svg {
    width: 260px;
    height: auto;

    @include breakpoint(mobile) {
      width: getRem(150);
    }
  }

  .fgdLogo {
    width: auto;
    max-height: getRem(53);

    @include breakpoint(mobile) {
      width: getRem(150);
    }
  }
}

.backButtonContainer {
  flex-shrink: 0;
  position: relative;

  @include breakpoint(mobile-tablet) {
    margin-right: getRem(8);
  }

  .backButton {
    width: getRem(30);
    height: getRem(30);
    background: var(--yellow);
    border-radius: 50%;
    border: none;
    cursor: pointer;
    display: flex;
    overflow: hidden;
    padding: getRem(7);
    position: relative;

    span {
      width: 100%;
      height: 100%;
      font-size: 2px;
      line-height: 2px;
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
    }
  
    svg {
      width: getRem(16);
      height: auto;
      margin: auto !important;
    }

    &:hover,
    &:focus {
      & + .backTooltip {
        display: block;
      }
    }
  }

  .backTooltip {
    width: getRem(268);
    background: var(--yellow);
    border-radius: getRem(5);
    box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.25);
    display: none;
    position: absolute;
    top: calc(100% + 10px);
    left: getRem(-8);

    @include breakpoint(mobile-tablet) {
      left: auto;
      right: getRem(-8);
    }

    .backTooltipInner {
      padding: 16px 18px 18px;
      position: relative;

      &:before {
        content: '';
        width: 0;
        height: 0;
        border-bottom: getRem(10) solid var(--yellow);
        border-left: getRem(11) solid transparent;
        border-right: getRem(11) solid transparent;
        display: inline-block;
        position: absolute;
        top: getRem(-8);
        left: getRem(14);

        @include breakpoint(mobile-tablet) {
          left: auto;
          right: getRem(14);
        }
      }

      p {
        @include font(16, 26);
        margin: 0;
      }
    }
  }
}

.navlinks {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  &.rightlinks {
    position: relative;
    z-index: 10;
  }

  @include breakpoint(mobile) {
    &>li {
      display: flex;
      align-items: center;
    }
  }

  li:not(:last-child) {
    margin-right: getRem(30);

    @include breakpoint(mobile) {
      margin-right: 0.5rem;
    }
  }

  // li.menuToggle {
  //   position: relative;
  // }

  li.notficationsLink {
    position: relative;
    z-index: 1;

    a.css-ujd0de {
      svg {
        width: getRem(26);
        height: auto;
      }
    }

    &>div {
      &>div:last-child {
        opacity: 1;

        img {
          display: none !important;
        }
      }
    }
  }

  li.bookmarkLink {
    display: flex;

    button {
      height: getRem(26);
    }
  }

  li.profileLinkGroup {
    display: flex;
    align-items: center;
    flex-shrink: 0;
  }

  button {
    appearance: none;
    margin: 0;
    padding: 0;
    border: 0 solid transparent;
    color: var(--color);
    background-color: transparent;
    font-weight: bold;
    cursor: pointer;
  }
}

.buttonsContainer {
  display: flex;
  align-items: flex-end;
  padding-right: getRem(16);

  @include breakpoint(mobile) {
    display: none;
  }
}

.fgdBtn {
  @include font(20, 28);
  margin: auto 0 auto auto;
  padding: getRem(8) getRem(24);
  text-align: center;
  white-space: nowrap;
}

.logoutBtn {
  background: none;
  border: none;
  color: var(--red);
  cursor: pointer;
  margin: auto 0;
  padding: getRem(8) getRem(24);

  &:hover {
    text-decoration: underline;
  }

  &:focus {
    outline: 1px solid var(--red);
  }
}

.navlink {
  &:not(last-child) {
    margin-right: 1em;
  }

  a {
    font-weight: bold;
    color: var(--color);
  }
}

.mobileNav {
  position: fixed;
  top: -#{getRem(20)};
  left: 0;
  width: 100vw;
  max-height: 100vh;
  min-height: -webkit-fill-available;
  min-height: stretch;
  background-color: var(--yellow);
  z-index: 2;
  transform: translate3d(0, -100%, 0);
  transition: transform 0.33s md-ease(ease-in);
  border-bottom-left-radius: getRem(10);
  padding: var(--nav-height) 1rem 0;
  overflow-y: auto;
  --color: var(--black) !important; // avoid color swap on mobile search on homepage

  &>div {
    padding-top: getRem(150);
    // position: relative;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 1rem 0 0;

    &:last-of-type {
      padding-bottom: 3rem;
    }
  }

  li {
    // TODO: Determine which way we want to handle this on smaller height devices
    // @media screen and (min-height: 750px) {
    padding: 0.25em 0;

    // }
    span {
      font-size: getRem(13);
      @include lineHeightRatio(13, 18);
      letter-spacing: getRem(1.63);
      white-space: normal;
    }

    a,
    button {
      background: none;
      border: none;
      font-size: getRem(18);
      font-weight: bold;
      @include lineHeightRatio(18, 25);
      color: var(--black);
      padding: 0;

      &:active,
      &:hover {
        color: var(--red);
      }
    }

    :global {

      .submit-an-idea,
      .submit-your-marketing-case-study {
        font-weight: bold !important;
      }
    }
  }

  &::after {
    content: "";
    position: sticky;
    display: block;
    bottom: 0;
    left: 0;
    width: 100%;
    height: getRem(125);
    // background-color: rebeccapurple;
    // z-index: 1000;
    pointer-events: none;
    background: linear-gradient(to bottom, rgba(#ffbc0d, 0) 0%, rgba(#ffbc0d, 1) 80%);
  }

  .mobileNavList {
    padding-top: 0;

    .parentHeader,
    button {
      font-weight: bold;
      font-size: getRem(18);
      @include lineHeightRatio(18, 25);
      color: var(--black);
      margin: 0;
      display: flex;
      align-items: center;

      svg {
        margin-left: getRem(8);

        path {
          stroke: var(--black);
        }
      }
    }

    a {
      font-size: getRem(18);
      @include lineHeightRatio(18, 25);
    }

    button {
      background: none;
      border: none;
      cursor: pointer;
      padding: getRem(3) 0 0;
      text-align: left;
    }

    ul {
      padding: getRem(8) 0 0 getRem(12);

      li {
        a {
          font-weight: normal;
        }
      }
    }

    .submenuList {
      display: none;

      &.show {
        display: block;
      }
    }
  }
}

.mobileNav[data-active="true"] {
  transform: translate3d(0, 0, 0);
  transition: transform 0.33s md-ease(ease-out);
  box-shadow: 0 7px 18px 0 rgba(0, 0, 0, 0.51);
}

.mobileProfile {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: auto;
  // min-height: 200px;
  padding-top: var(--nav-height);
  background-color: var(--white);
  z-index: 3;
  border-bottom-left-radius: getRem(30);
  border-bottom-right-radius: getRem(30);
  transform: translate3d(0, -100%, 0);
  transition: transform 0.33s md-ease(ease-in);
}

.mobileProfile[data-active="true"] {
  transform: translate3d(0, 0, 0);
  transition: transform 0.33s md-ease(ease-out);
  // box-shadow: 0 7px 18px 0 rgba(0, 0, 0, 0.51);
}

.mobileNav,
.mobileProfile {
  --max-width: 100%;
}

.mobileProfile>div {
  display: Grid;
  gap: 1rem;
  grid-template-columns: auto 1fr;
  padding-top: getRem(30);
  padding-bottom: getRem(37);

  img {
    border-radius: 100%;
  }
}

.noShadow {
  box-shadow: none;
}

.info {
  font-weight: bold;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;

  span:first-child {
    font-size: getRem(26);
    @include lineHeightRatio(26, 30);
    color: var(--black);
    letter-spacing: 0;
  }

  span:last-child {
    a {
      font-size: getRem(13);
      @include lineHeightRatio(13, 24);
      color: #6f6f6f;
    }
  }
}

.mobileSearchButton {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profileToggle {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: getRem(5);

  img {
    border-radius: 100%;
    object-fit: cover;
  }

  svg {
    margin-left: 0.5rem;
    margin-top: -0.25rem;
  }
}

.mobileParent {
  z-index: 1001;
  position: relative;
}

.navlinks .notficationsLink .defaultNotifButton {
  display: flex;
  position: relative;

  span {
    width: 100%;
    height: 100%;
    font-size: 2px;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }

  svg {
    width: 24px;
    height: auto;
    margin: auto;
  }
}