@import "../../global/mixins";

.footer {
    background: #FFFFFF;

	:global {

		.wrapper {
			@include breakpoint(mobile-tablet) {
				padding-inline: 0;
			}
		}
	}
}

.row {
    display: flex;
    justify-content: space-between;
	align-items: flex-end;
	flex-wrap: wrap;
    gap: 64px;

    @include breakpoint(mobile-tablet) {
        flex-direction: column;
		align-items: flex-start;
        gap: getRem(40);
    }
}

.leftColumn {
	flex: 1.25;

    .logo {
        display: inline-block;
    }

    p {
        @include font(16, 20);
        margin: 0;

		& + p {
			margin-top: getRem(16);
		}
    }

	a {
		color: #000;
	}
}

.smallText {
	display: flex;
	gap: 20px;

	@include breakpoint(mobile-tablet) {
        flex-direction: column;
    }
}

.copyright {
	@include font(14, 14);
	margin: 0;
}

.rightColumn {
	flex: .75;
}

.listGrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;

    @include breakpoint(mobile) {
        grid-template-columns: 1fr;
    }

    ul {
        display: flex;
        flex-direction: column;
        gap: 24px;
        list-style: none;
        margin: 0;
        padding: 0;

        @include breakpoint(mobile) {
            gap: 4px;
        }
    }

    a {
        color: #000000;
        @include font(16, 24);
        font-weight: 400;
    }
}

.divider {
    width: 100%;
    height: getRem(1);
    background: #D8D8D8;
    display: inline-block;
    margin: getRem(48) 0;
}

.footerBottom {
    p {
        color: #292929;
        @include font(11, 16);
        margin: getRem(4) 0 0;
    }
}