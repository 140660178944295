@import "../global/mixins";

.topnav {
    // --color: var(--black);
    --background: white;
    --color: var(--text-color);
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
	height: getRem(115);
    // height: 100%;
    background-color: var(--background);
    color: var(--color);
    display: flex;
    // min-height: var(--nav-height);
    align-items: center;
    justify-content: flex-start;
    margin-left: auto;
    margin-right: auto;
    z-index: 1002;
    transition: color 0.25s linear, background-color 0.25s linear, transform 0.25s md-ease(ease-in-out);
    transform: translate3d(0px, -100%, 0px);

    &.sticky {
        transform: translate3d(0px, 0px, 0px);
    }

    & > .wrapper {
        padding-left: 60px;
        padding-right: 60px;

        @include breakpoint(tablet) {
            padding-left: getRem(32);
            padding-right: getRem(32);
        }

        @include breakpoint(mobile) {
            padding-left: 16px;
            padding-right: 16px;
        }
    }

    .grid {
        position: relative;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 88px;

        @include breakpoint(mobile) {
            gap: getRem(24);
        }
    }

    .right {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 40px;
    }

    .logo {
        position: relative;

        a {
            display: inline-block;
        }

        span {
            width: 100%;
            height: 100%;
            font-size: 2px;
            line-height: 2px;
            opacity: 0;
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
        }

        svg {
            width: 100%;
            min-width: getRem(240);

            @include breakpoint(mobile) {
                min-width: 0;
            }
        }
    }

    ul:not(.submenu) {
        display: flex;
        gap: 32px;
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            padding: 44px 0;
            position: relative;

            &.active {
                &:after {
                    content: '';
                    width: 100%;
                    height: 4px;
                    background: var(--red);
                    border-radius: 4px 0px;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                }

                a {
                    font-weight: bold;
                }
            }

            .navItem {
                display: flex;
                align-items: center;
            }

            a {
                color: #292929;
                display: flex;
                align-items: center;
                @include font(16, 24);
                font-weight: normal;
                letter-spacing: -0.15px;
                text-wrap: nowrap;
            }

            button {
                @include font(16, 24);
				display: flex;
				gap: getRem(2);
                font-weight: normal;
                letter-spacing: -0.15px;
                text-wrap: nowrap;
				text-align: left;
                padding: 0;
                background: none;
                border: none;
                cursor: pointer;

                &:focus-visible {
                    outline: 1px solid #000000;
                }

                svg {
                    width: 24px;
                    height: 24px;
                }
            }

            .submenu {
                border-radius: 4px;
                background: #FFFFFF;
                box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.04), 0px 1px 5px 0px rgba(0, 0, 0, 0.04);
                display: flex;
                flex-direction: column;
                gap: 12px;
                list-style: none;
                padding: 20px;
                position: absolute;
                top: calc(100% - 24px);
                left: 0;

                li {
                    padding: 0;

                    a {
                        text-wrap: nowrap;
                    }
                }
            }
        }
    }

    .hamburgerBtn {
		color: #000;
        background: none;
        border: none;
        cursor: pointer;

        svg {
            path {
                fill: #000000;
            }
        }
    }
}

.wrapper {
	@media (max-width: 1224px) {
		max-width: unset;
    }

	@include breakpoint(mobile) {
		margin-inline: 0;
		padding-inline: 0;
	}
}

.mobileMenu {
    width: 100%;
    height: 100vh;
    max-height: 100vh;
    min-height: -webkit-fill-available;
    min-height: stretch;
    background: #fff;
    padding: 112px 32px 32px;
    position: fixed;
    // top: -1.25rem;
    left: 0;
    transform: translate3d(0, -100%, 0);
    transition: transform 0.33s cubic-bezier(0.4, 0, 1, 1);
	overflow: auto;
    z-index: 1001;

    &[data-active="true"] {
        transform: translate3d(0, 0, 0);
        transition: transform 0.33s md-ease(ease-out);
        box-shadow: 0 7px 18px 0 rgba(0, 0, 0, 0.51);
    }

    ul {
        list-style: none;
        flex-direction: column;
        gap: 0;

		&:not(.submenu) {
			margin-bottom: getRem(30);
			padding: 0 0 getRem(30) 0;
			border-bottom: 1px solid #CECECE;
		}

        li {
            padding-block: getRem(10);

            &.active {
                &:after {
                    display: none;
                }

                a {
                    font-weight: bold;
                    position: relative;

                    &:after {
                        content: '';
                        width: 100%;
                        height: 4px;
                        background: var(--red);
                        border-radius: 4px 0px;
                        display: inline-block;
                        position: absolute;
                        top: 100%;
                        left: 0;
                    }
                }
            }

            a {
                color: #292929;
                display: inline-flex;
                align-items: center;
                @include font(18, 22);
                font-weight: normal;
            }

            ul {
				margin-top: getRem(8);
                padding-left: getRem(20);

				li a {
					font-size: getRem(14);
				}
            }
        }
    }
}

.subnavToggle {
	@include font(18, 24);
	display: flex;
	gap: getRem(2);
	font-weight: normal;
	letter-spacing: -0.15px;
	text-wrap: nowrap;
	text-align: left;
	color: #000;
	padding: 0;
	background: none;
	border: none;
	cursor: pointer;

	&:focus-visible {
		outline: 1px solid #000000;
	}

	svg {
		width: 24px;
		height: 24px;
	}
}

.navLeft {
	display: flex;
	align-items: center;
	gap: getRem(16);
}

.backButtonContainer {
	flex-shrink: 0;
	position: relative;
  
	@include breakpoint(mobile-tablet) {
	  margin-right: getRem(8);
	}
  
	.backButton {
	  width: getRem(30);
	  height: getRem(30);
	  background: var(--yellow);
	  border-radius: 50%;
	  border: none;
	  cursor: pointer;
	  display: flex;
	  overflow: hidden;
	  padding: getRem(7);
	  position: relative;
  
	  span {
		width: 100%;
		height: 100%;
		font-size: 2px;
		line-height: 2px;
		opacity: 0;
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;
	  }
	
	  svg {
		width: getRem(16);
		height: auto;
		margin: auto !important;
	  }
  
	  &:hover,
	  &:focus {
		& + .backTooltip {
		  display: block;
		}
	  }
	}
  
	.backTooltip {
	  width: getRem(268);
	  background: var(--yellow);
	  border-radius: getRem(5);
	  box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.25);
	  display: none;
	  position: absolute;
	  top: calc(100% + 10px);
	  left: getRem(-8);
  
	  @include breakpoint(mobile-tablet) {
		left: auto;
		right: getRem(-8);
	  }
  
	  .backTooltipInner {
		padding: 16px 18px 18px;
		position: relative;
  
		&:before {
		  content: '';
		  width: 0;
		  height: 0;
		  border-bottom: getRem(10) solid var(--yellow);
		  border-left: getRem(11) solid transparent;
		  border-right: getRem(11) solid transparent;
		  display: inline-block;
		  position: absolute;
		  top: getRem(-8);
		  left: getRem(14);
  
		  @include breakpoint(mobile-tablet) {
			left: auto;
			right: getRem(14);
		  }
		}
  
		p {
		  @include font(16, 26);
		  margin: 0;
		}
	  }
	}
  }

.searchToggle {
	display: flex;
	align-items: center;
	gap: getRem(8);
	color: #292929;
	background-color: transparent;
	border: 0;
	border-radius: 0;

	@media (max-width: 1224px) {
		width: 100%;
		padding: 0;
        background-color: #fff;
    }

	svg {
		display: block;
		width: getRem(22);
	}

	span {
		display: block;

		@media (max-width: 1224px) {
			@include font(18, 22);
		}
	}

	&:hover {
		cursor: pointer;

		span {
			text-decoration: underline;
		}
	}
}